import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/',
    component: layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/home')
      },
        // 关于我们
      {
        path: '/walk-into-peinier',
        name: 'walkIntopeinier',
        //component: () => import('../views/aboutUs/walkIntopeinier')
        component: () => import('../views/aboutUs/walkIntopeinier.vue')
      },
      {
        path: '/head-speak',
        name: 'headSpeak',
        component: () => import('../views/aboutUs/headSpeak')
      },
      {
        path: '/development-history',
        name: 'developmentHistory',
        component: () => import('../views/aboutUs/developmentHistory')
      },
      {
        path: '/corporate-honors',
        name: 'corporateHonors',
        component: () => import('../views/aboutUs/corporateHonors')
      },
      {
        path: '/social-responsibility',
        name: 'socialResponsibility',
        component: () => import('../views/aboutUs/socialResponsibility')
      },
        // 研发中心
      {
        path: '/rd-information',
        name: 'rdInformation',
        component: () => import('../views/R&D Center/information')
      },
      {
        path: '/platform',
        name: 'platform',
        component: () => import('../views/R&D Center/platform')
      },
      {
        path: '/technology-rd',
        name: 'technologyRD',
        component: () => import('../views/R&D Center/technologyRD')
      },
        // 技术与产品
      {
        path: '/soybean',
        name: 'soybean',
        component: () => import('../views/TechnologyandProducts/soybean'),
        meta: {
          title: "大豆"
        }
      },
      {
        path: '/peanut',
        name: 'peanut',
        component: () => import('../views/TechnologyandProducts/peanut'),
        meta: {
          title: "花生"
        }
      },
      {
        path: '/edible-beans',
        name: 'edibleBeans',
        component: () => import('../views/TechnologyandProducts/edibleBeans'),
        meta: {
          title: "食用豆"
        }
      },
      {
        path: '/green-manure',
        name: 'greenManure',
        component: () => import('../views/TechnologyandProducts/greenManure'),
        meta: {
          title: "绿肥"
        }
      },
      {
        path: '/pasture',
        name: 'pasture',
        component: () => import('../views/TechnologyandProducts/pasture'),
        meta: {
          title: "牧草"
        }
      },
      {
        path: '/application-cases',
        name: 'applicationCases',
        component: () => import('../views/TechnologyandProducts/applicationCases'),
        meta: {
          title: "应用案例"
        }
      },
      {
        path: '/company-news',
        name: 'companyNews',
        component: () => import('../views/companyNews')
      },
      {
        path: '/media-coverage',
        name: 'mediaCoverage',
        component: () => import('../views/mediaCoverage')
      },
      {
        path: '/industry-information',
        name: 'industryInformation',
        component: () => import('../views/industryInformation')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
