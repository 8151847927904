<template>
  <div class="app-container">
    <div class="header">
      <nav class="navbar container">
        <div class="container-fluid header">
          <!-- Brand and toggle get grouped for better mobile display -->
          <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a class="navbar-img" href="#">
              <img src="@/assets/images/logo.png" width="50px" height="100px">
            </a>
          </div>
          <div class="flex1"></div>·
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
            <ul class="nav navbar-nav navbar-right">
              <li class="dropdown" v-for="item in navData" :key="item.path">
                <template v-if="item.children && item.children.length">
                  <a :href="item.path" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
                    aria-expanded="true">{{ item.label }}<span class="caret"></span> </a>
                  <ul class="dropdown-menu">
                    <li v-for="(child, index) in item.children" :key="item.path + index"><a :href="child.path">{{
                      child.label
                    }}</a></li>
                  </ul>
                </template>
                <template v-else>
                  <a :href="item.path">{{ item.label }} </a>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </div>
    <div class="app-main">
      <router-view></router-view>
    </div>
    <footer>
      <div class="footer">
        <div class="container row">
          <div class="left col-md-6">
            <div class="footer-logo img-center"><img src="@/assets/images/logo.png"></div>
            <div class="footer-nav"> <a href="/">网站首页</a><span>|</span><a
                href="/walk-into-peinier">关于我们</a><span>|</span><a href="/rd-information">研发中心</a><span>|</span><a
                href="/soybean">技术与产品</a><span>|</span><a href="/company-news">新闻资讯</a><span>|</span><a
                href="/contact">联系我们</a> </div>
          </div>
          <div class="right col-md-6 ">
            <div class="footer-text">
              <div class="flex">
                <div>
                  <p><span class="i1">18838215835</span></p>
                  <p><span class="i3">office@leadst.cn</span></p>
                </div>
                <img class="footer-ewm" src="@/assets/images/photos/众创.png">
              </div>
              <p><span class="i2">郑州高新技术产业开发区科学大道136号郑州轻工业大学星空众创空间210室</span></p>
            </div>

          </div>

        </div>
        <div class="footer-copy">
          <div class="container clearfix"> <a href="https://beian.miit.gov.cn/" target="_blank"> 冀ICP备07006121号</a> <a
              href="http://www.leadst.cn/sitemap.xml" target="_blank">XML地图</a> <a href="" target="_blank">城市分站</a></div>
        </div>
      </div>
      <div class="footer-fix">
        <ul class="clearfix">
          <li><a href=""><i class="i1"></i>网站首页</a></li>

          <li><a href=""><i class="i2"></i>技术与产品</a></li>

          <li><a href=""><i class="i4"></i>联系我们</a></li>

          <li><a href="tel:0335-8500208"><i class="i3"></i>电话咨询</a></li>
        </ul>
      </div>
    </footer>


  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      navData: [
        { label: '网站首页', path: '/' },
        {
          label: '关于我们', path: '/walk-into-peinier', children: [
            { label: '走进培尼尔', path: '/walk-into-peinier' },
            { label: '负责人致辞', path: '/head-speak' },
            { label: '发展历程', path: '/development-history' },
            { label: '企业荣誉', path: '/corporate-honors' },
            { label: '社会责任', path: '/social-responsibility' },
          ]
        },
        {
          label: '研发中心', path: '/rd-information', children: [
            { label: '研发中心资讯', path: '/rd-information' },
            { label: '研究平台', path: '/platform' },
            { label: '技术研发', path: '/technology-rd' },

          ]
        },
        {
          label: '技术与产品', path: '/soybean', children: [
            { label: '大豆菌剂', path: '/soybean' },
            { label: '花生菌剂', path: '/peanut' },
            { label: '食用豆', path: '/edible-beans' },
            { label: '绿肥菌剂', path: '/green-manure' },
            { label: '牧草', path: '/pasture' },
            { label: '应用案例', path: '/application-cases' },
          ]
        },
        {
          label: '新闻资讯', path: '/company-news', children: [
            { label: '公司新闻', path: '/company-news' },
            { label: '媒体报道', path: '/media-coverage' },
            { label: '行业资讯', path: '/industry-information' },
          ]
        },
        { label: '联系我们', path: '/contact' },
      ],
    }
  }
}
</script>

<style>
/*页头*/
.header {
  /*position: fixed;*/
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-color: rgba(9, 147, 57, 1.0);
}

.header .nav .dropdown-menu li>a {
  color: #fff;
}

@media (min-width: 768px) {
  .header .nav .dropdown-menu li>a {
    color: #333;
  }
}




.dropdown-menu {
  background: rgb(255 255 255 / 50%);
}

.icon-bar {
  background-color: #fff;
}

.navbar-img img {
  height: 50px;
  width: auto;
  margin-top: 5px;
}

@media (min-width: 992px) {
  footer .footer-text .flex {
    justify-content: end;
  }

  footer .footer-text {
    text-align: right;
  }
}

.header .nav li>a:focus,
.header .nav li>a:hover {
  background-color: #099339;
  border-color: #337ab7;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: #099339;
  border-color: #337ab7;
}

.flex {
  display: flex;
}

.footer-ewm {
  width: 90px;
  background-color: #fff;
  margin-top: 5px;
  margin-left: 10px;
}

.header .logo {
  float: left;
  font-size: 0;
  height: 50px;
  line-height: 60px;
  text-align: left;
  padding: 10px 0;
}

/* .header .nav {
  float: right;
} */
/* 
.header .nav li {
  margin-left: 10px;
  float: left;
} */

.header .nav li>a {
  display: block;
  font-size: 16px;
  line-height: 60px;
  position: relative;
  color: #fff;
  padding: 0 35px;
}

/* .header .nav li>a::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #229f53 !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  content: '';
} */

.header .nav li .arr {
  float: right;
  height: 10px;
  width: 10px;
  position: relative;
}

.header .nav li .arr:after {
  content: '';
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transform: rotate(220deg);
  transform: rotate(220deg);
  right: 5px;
  top: 33px;
}

.header .nav li.dropdown {
  position: relative;
}

.header .nav .dropdown-box {
  position: absolute;
  top: 70px;
  min-width: 150px;
  display: none;
  z-index: 99999;
  text-align: center;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.70)
}

.header .nav li:hover .dropdown-box {
  display: block;
}

.header .nav .dropdown-box p {
  margin: 0;
}

.header .nav .dropdown-box a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  font-size: 14px;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 40px;
  color: #333;
}

.header .nav .dropdown-box a:hover {
  background-color: #229f53;
  color: #fff;
}

.header .nav li.open>a:after,
.header .nav li.active>a:after,
.header .nav li>a:hover:after {
  width: 100%;
}

.header .nav li.open .dropdown-box {
  display: block;
}

.header .sc {
  float: right;
  position: relative;
  margin-left: 40px;
}

.header .sc-box {
  position: absolute;
  right: 0;
  top: 100%;
  overflow: hidden;
  display: none;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.header .sc-box input {
  display: block;
  padding: 9px 62px 9px 12px;
  border: 1px solid transparent;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  width: 200px;
}

.header-active {
  background: rgba(0, 0, 0, 0.70);
}

.footer-fix {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  display: none;
}

.footer-fix li {
  float: left;
  width: 25%;
}

.footer-fix li a {
  display: block;
  text-align: center;
  padding: 7px 0;
  font-size: 12px;
  line-height: 1;
}

.footer-fix li i {
  display: block;
  height: 20px;
  background: no-repeat center;
  background-size: contain;
  margin-bottom: 4px;
}

/*图片*/
.img-center {
  text-align: center;
  font-size: 0;
}

.img-center img {
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.img-cover {
  overflow: hidden;
}

.img-cover span {
  display: block;
  width: 100%;
  background: no-repeat center / cover;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}


.more-big {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  line-height: 50px;
  margin-top: 50px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(214, 214, 214)), to(rgb(255, 255, 255)));
  background-image: linear-gradient(to top, rgb(214, 214, 214), rgb(255, 255, 255));
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.more-big span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  padding: 0 40px;
}

.more-big:hover {
  background: #229f53;
  color: #fff;
}

.footer {
  background-color: #666666;
  padding-top: 10px;
  color: #fff;
  border-top: 5px solid #fff;
}

.footer a {
  color: #fff;
}

.footer a:hover {
  color: #cccccc;
}

.footer-left {
  margin-right: 520px;
}

.footer-logo {
  text-align: left;
  margin-bottom: 20px;
}

.footer-nav {
  font-size: 14px;
}

.footer-nav span {
  padding: 0 10px;
  opacity: 0.3;
}




.footer-text p {
  margin: 10px 0;
}

.footer-text p span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  background: no-repeat 0 center;
}

.footer-copy {
  margin-top: 10px;
  background-color: #575757;
  padding: 20px 0;
  font-size: 16px;
}

.footer-logo img {
  height: 45px;
  margin-top: 5px;
}
</style>

<style>
.in-about {
  margin: 0;
}

.in-about dt {
  float: right;
  width: 30%;
  margin-top: 10px;
}

.in-about .img-center2 {
  float: right;
  width: auto;
  padding: 0 30px;
}

.in-about dd {
  margin: 0 0% 0 0;
  padding: 0px 5% 0 0;
  overflow: hidden;
}

.in-about dd p {
  margin: 0;
  font-size: 14px;
  line-height: 2;
  text-align: justify;
  text-indent: 2em;
}

.in-about ul {
  overflow: hidden;
  margin-top: 3%;
  border-bottom: 1px solid #ddd;
  margin-left: -30px;
}

.in-about li {
  float: left;
  width: 20%;
  padding-bottom: 30px;
  margin-bottom: -1px;
}

.in-about li .wrap {
  line-height: 1;
  border-right: 1px solid #eee;
  margin-left: 30px;
}

.in-about li p {
  font-size: 14px;
  line-height: 1;
  text-align: left;
  margin-top: 15px;
  text-indent: 0;
}

.in-about li .num strong {
  font-size: 30px;
  color: #229f53;
  font-family: Impact;
  margin-right: 5px;
}

.in-about li.active {
  border-bottom: 3px solid #229f53;
}

.in-about li:last-child .wrap {
  border-right: 0;
}

.about-s dt {
  margin-top: 0;
}

.about-s dd h4 {
  font-size: 28px;
  margin: 0 0 20px;
}

.about-s ul {
  border-bottom: 0;
}

.about-s li {
  margin-bottom: 0;
}

.more-big {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  line-height: 50px;
  margin-top: 50px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgb(214, 214, 214)), to(rgb(255, 255, 255)));
  background-image: linear-gradient(to top, rgb(214, 214, 214), rgb(255, 255, 255));
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.more-big span {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  padding: 0 40px;
}

.more-big i {
  display: inline-block;
  /*background: url(../images/icon2a.png) no-repeat center;*/
  width: 50px;
  border-left: 1px solid #ccc;
  height: 50px;
  vertical-align: middle;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.more-big:hover {
  background: #229f53;
  color: #fff;
}
</style>
