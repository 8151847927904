<template>
    <!-- <ul class="flex">
        <li :class="[item.path === routePath ? 'on' : '']" v-for="item in nav" :key="item.label"><a :href="item.path">{{
            item.label }}</a></li>
    </ul> -->
    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <li  :class="['swiper-slide',item.path === routePath ? 'on' : '']"  v-for="item in nav" :key="item.label"><a
                    :href="item.path">{{
                        item.label }}</a></li>
        </div>

    </div>
</template>
<script>
export default {
    name: 'secNav',
    props: {
        nav: {
            type: Array,
            default() {
                return []
            }
        }
    },
    computed: {
        routePath() {
            return this.$route.path
        }
    },
    mounted() {
        var swiper = new Swiper(".mySwiper", {
            slidesPerView: 3,
            spaceBetween: 20,
            freeMode: true,
        });
    }
}
</script>
<style scoped>
.swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
li {
    width:1.7rem !important;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;      
    margin-right: 20px;
    border: #ddd 1px solid;
}

.on {
    background: #229f53;

}

.on a {
    color: #fff;
}
</style>